import * as React from 'react'
import '../assets/css/index.css'
import Navigation from '../components/Navigation'; 
import Footer from '../components/Footer'
import { Container, Modal, Row, Col, Button } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from 'gatsby-plugin-next-seo';

// markup
const IndexPage = () => {
  return (
    <main>
    <Navigation/>
    <GatsbySeo
        title='Pool - Pinches Cue Club & The Venue - Snooker, Pool and Darts | Norwich Snooker and Pool Club | Private Function Room'
        description='Looking for somewhere to play Pool in Norwich? Book a table before or come down to see if we have any availability'
        />
        <div className='green-section'>
    <Container>
          <Row className='pb-3'>
            <Col lg={12} md={12} sm={12} xs={12} className="green-section ">
            <div className='container'>
              <h1 className='pt-3 pb-3'>
              Pool Table Information
              </h1>
              <p>We currently have 8 Supreme pool tables, six in the main snooker and pool area with the other two in the bar area. These are re-clothed frequently to ensure that the condition of the tables are maintained to a high level.</p>
              <h2 className='pb-3'>
                Hourly Rate
                </h2>
                <p>10:00 - 12:00 | £4.00 per hour</p>
                <p>12:00 - 18:00 | £6.00 per hour</p>
                <p>18:00 - 22:00 | £7.00 per hour</p>
                <hr></hr>
            </div>
          </Col>
          <Col lg={6} md={12} sm={12} xs={12} className="green-section mb-5">
            <StaticImage src="../assets/img/pinches-cue-club-pool-norwich (1).jpg"
                  alt="A hero image"
                  placeholder="blurred"
                  layout="constrained" />
          </Col>
          <Col lg={6} md={12} sm={12} xs={12} className="green-section mb-5">
            <StaticImage src="../assets/img/pool-areas.jpg"
                  alt="A hero image"
                  placeholder="blurred"
                  layout="constrained" />
                            
          </Col>
          <Col lg={6} md={12} sm={12} xs={12} className="green-section mb-5">
            <StaticImage src="../assets/img/pinches-cue-club-pool-norwich (1).jpg"
                  alt="A hero image"
                  placeholder="blurred"
                  layout="constrained" />
          </Col>
          <Col lg={6} md={12} sm={12} xs={12} className="green-section mb-5">
            <StaticImage src="../assets/img/poolarea.jpg"
                  alt="A hero image"
                  placeholder="blurred"
                  layout="constrained" />
                            
          </Col>
          <hr></hr>
          </Row>
                    </Container>
    </div>

    <div className='black-section'>
    <Container>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12} className="black-section ">
            <div className='container'>
              <h3 className='pt-3 pb-3'>
               Norwich Pool Tournament Information 
              </h3>
              <p><p>Pinches Cue Club runs monthly pool tournaments, you can find out more by contacting the club. These are normally run on a Saturday or Sunday and limited to 16 players.</p></p>
              <h3 className='pt-3 pb-3'>
               Norwich Pool League Information 
              </h3>
              <p>Pinches Cue Club has several teams competing in the local pool leagues, if you want to learn more about the league please use the link below.</p>
              <Button href="http://www.tunmorepoolleague.co.uk/" variant="success" target="_blank">Norwich Tunmore Pool League</Button>

              
            </div>
          </Col>
        </Row>
    </Container>
    <Footer/>
    </div>
  </main>
  )
}

export default IndexPage
